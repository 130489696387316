import React from "react"
import styled from "styled-components"
import CategoryPanel, { EmptyPanel } from "./CategoryPanel"
import MobileCategoryPanel from "./MobileCategoryPanel"

const GroupWrapper = styled.div`
  min-height: 435px;
  display: flex;
`
const SimpleContainer = styled.div`
  position: relative;
  z-index: ${props => (props.isTop ? "999" : 1)};
`
const CategoryPanelGroup = ({
  categoryData,
  resourceData,
  subtagsData,
  selectedCategory,
  setCategory,
}) => {
  let curIndex = 6
  categoryData.forEach((categoryItem, idx) => {
    if (categoryItem.uid === selectedCategory) {
      curIndex = idx
    }
  })

  return (
    <>
      <div className="desktop-activities">
        <GroupWrapper>
          {categoryData.map((categoryItem, i) => (
            <SimpleContainer
              id={i}
              key={i}
              isTop={categoryItem.uid === selectedCategory}
              onClick={() => setCategory(categoryItem.uid)}
            >
              {categoryItem.uid === selectedCategory ? (
                <CategoryPanel
                  key={i}
                  id={i}
                  isTop={true}
                  isBackward={categoryItem.uid !== selectedCategory}
                  categoryItemData={categoryItem}
                  subtagsData={subtagsData}
                  resourceData={resourceData}
                  categoryData={categoryData}
                />
              ) : (
                <EmptyPanel
                  key={i}
                  id={i}
                  categoryItemData={categoryItem}
                  isBackward={i > curIndex}
                  isLast={i === categoryData.length - 1}
                />
              )}
            </SimpleContainer>
          ))}
        </GroupWrapper>
      </div>
      <div
        className="mobile-activities"
        css={`
          filter: drop-shadow(0px 14px 24px rgba(0, 47, 108, 0.07));
          border-radius: 8px;
        `}
      >
        {categoryData.map((catergoryItem, i) => (
          <MobileCategoryPanel
            key={i}
            id={i}
            isTop={selectedCategory === catergoryItem.uid}
            categoryItemData={catergoryItem}
            categoryData={categoryData}
            resourceData={resourceData}
          />
        ))}
      </div>
    </>
  )
}

export default CategoryPanelGroup
