import { graphql, Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { toGreeklish } from "greek-utils"
import * as React from "react"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import { FreeMode, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss/navigation"
import CategoryActivitiesPanel from "../components/Category/CategoryActivitiesPanel"
import CategoryPanelGroup from "../components/Category/CategoryPanelGroup"
import CategorySelect from "../components/Category/CategorySelect"
import CategoryButton from "../components/common/CategoryButton"
import ContentBox from "../components/common/ContentBox"
import Section from "../components/common/Section"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../components/ui/navbar"
import { getCategoryFilters } from "../utils/staticData"
import Modal from "react-bootstrap/Modal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "react-bootstrap"

const CarouselContainer = styled.div`
  display: flex;
  padding: 80px 0;

  .carousel-left {
    ${down("lg")} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .carousel-buttons {
    display: flex;
    margin-top: 52px;
    button {
      padding: 8px 25px;
      outline: none;
      font-family: "CeraGR-Medium";
      font-size: 14px;
      line-height: 22px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      margin-right: 12px;
      margin-bottom: 8px;
      ${down("sm")} {
        width: 191px;
        padding: 13px;
      }
    }
    .primary {
      background: #ffffff;
      color: #002f6c;
      ${down("sm")} {
        margin-right: 0;
      }

      &:hover {
        background: #001838;
        color: #fff;
        border-color: #001838;
      }
    }
    .secondary {
      background: transparent;
      color: #ffffff;

      &:hover {
        background: #001838;
        color: #fff;
        border-color: #001838;
      }
    }
  }
  padding-left: 18vw;

  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    width: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  ${down("lg")} {
    flex-direction: column;
    padding: 20px 0;
    padding-left: 0;
    & > div {
      align-items: flex-start;
    }
    .carousel-buttons {
      flex-direction: column;
      margin-top: 24px;
    }
    .carousel-left {
      h1 {
        margin: 16px 0;
      }
    }
  }
`

const ContentBody = styled.div`
  h1 {
    /* w-50 mt-lg-5 pt-5 mb-lg-5 */
    margin-top: 40px;
    width: 50%;
  }
  & .category-filters {
    //d-flex mb-5
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 48px;
  }
  ${down("lg")} {
    & h1 {
      margin: 40px 0;
      width: 100%;
    }
    & .category-filters {
      display: none;
    }
  }
`

export default function Page({ location, data }) {
  const { t, i18n } = useTranslation()
  const resourceData = data.allPrismicResource.nodes
  const categoryData = data.allPrismicCategory.nodes
  const [category, setCategory] = React.useState(categoryData[0].uid)
  const subtagsData = data.allPrismicSubtag.nodes
  const featuredData = data.allPrismicFeatured.nodes[0].data
  const trends = data.allPrismicTrends.nodes.map(node => ({
    ...node.data,
    id: node.id,
  }))
  const [isPopupVisible, setIsPopupVisible] = React.useState(true)

  const category_filters = [
    { label: t("common:all") },
    ...getCategoryFilters(categoryData),
  ]

  if (i18n.resolvedLanguage === "en-us") {
    if (typeof window !== `undefined`) {
      window.location.href = `${window.location.origin}/en-us/about`
    }
  }

  return (
    <Layout location={location}>
      {featuredData.is_popup && (
        <Modal
          css={`
            &&& {
              .modal-dialog {
                max-width: 360px;
              }
            }
          `}
          centered
          show={isPopupVisible}
          onHide={() => setIsPopupVisible(false)}
        >
          <Modal.Body
            css={`
              &&& {
                padding: 16px;
              }
            `}
          >
            <GatsbyImage
              image={getImage(featuredData.announcement_image)}
              alt="announcement"
              width={328}
              height={472}
              objectFit="cover"
            />
          </Modal.Body>
          <Modal.Header
            closeButton
            css={`
              &&& {
                border-bottom: none;
                padding: 16px 24px;
              }
            `}
          >
            <a href={featuredData.announcement_url.url}>
              <Button
                css={`
                  &&& {
                    border-radius: 0;

                    color: #002f6c;
                    border-color: #002f6c;
                    width: 180px;

                    &:hover {
                      color: #fff;
                      background: #002f6c;
                    }
                  }
                `}
                variant="outline-dark"
              >
                Ενημερώσου
              </Button>
            </a>
          </Modal.Header>
        </Modal>
      )}
      <Seo title={t("index:seoTitle")} />
      <Section className="intro-section">
        <Navbar type="second" />
        <CarouselContainer className={featuredData.is_video ? "flex-wrap" : ""}>
          <div className="d-flex flex-column justify-content-between ">
            <div className=" align-items-center px-3 carousel-left ">
              <h1 className="text-white">
                Με τις μικρές επιχειρήσεις στο προσκήνιο
              </h1>
              <div className="carousel-buttons">
                <Link to="/members">
                  <button className="primary">Δίκτυο μελών</button>
                </Link>
                <Link to="/about">
                  <button className="secondary">Γνωρίστε τη ΓΣΕΒΕΕ</button>
                </Link>
              </div>
            </div>
          </div>
          {featuredData.is_video ? null : (
            // <VideoPlayer url={featuredData.hero_video?.embed_url} />
            <Swiper
              spaceBetween={12}
              slidesPerView={"auto"}
              freeMode={{
                enabled: true,
                sticky: true,
              }}
              modules={[FreeMode, Navigation]}
              navigation
              className="featured-swiper"
            >
              {featuredData?.hero
                .sort((a, b) => {
                  var dateA = new Date(a.hero_elements.document.data.post_date)
                  var dateB = new Date(b.hero_elements.document.data.post_date)
                  return dateB - dateA
                })
                .map((item, idx) => (
                  <SwiperSlide key={idx}>
                    <ContentBox
                      categoryData={categoryData}
                      resourceData={item.hero_elements.document}
                      key={idx}
                      margin="12px 8px"
                      width="390px"
                      height="400px"
                      dashboard={true}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
        </CarouselContainer>
      </Section>
      <section className="recommended-section">
        <header className="recommended-section__header">
          <h3 className="recommended-section__title">Προτεινόμενα</h3>
        </header>
        <ul className="recommended-list">
          {trends.map((trend, i) => (
            <li className="recommended-list__item" key={i}>
              <Link
                to={
                  trend.destination.type === "resource"
                    ? `/resource/${toGreeklish(trend.destination.uid)}`
                    : `/pages/${toGreeklish(trend.destination.uid)}`
                }
                key={trend.id}
                className="recommended-list__link"
              >
                {trend.hashtag.text}
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <Section className="home-category">
        <ContentBody className="container">
          <h1>Τα πεδία δράσης της ΓΣΕΒΕΕ</h1>
          <div className="mobile-activities">
            <CategorySelect
              options={category_filters}
              category={category}
              setCategory={setCategory}
            />
          </div>

          <div className="category-filters">
            {category_filters.slice(1).map(item => (
              <CategoryButton
                isSelected={category === item.value}
                onClick={() => setCategory(item.value)}
                key={item.value}
              >
                {item.label}
              </CategoryButton>
            ))}
          </div>
          <CategoryPanelGroup
            resourceData={resourceData}
            categoryData={categoryData}
            subtagsData={subtagsData}
            selectedCategory={category}
            setCategory={setCategory}
          />
        </ContentBody>
      </Section>
      <Section className="homehero-section notable-projects">
        <CategoryActivitiesPanel
          category={category}
          setCategory={c => setCategory(c)}
          resourceData={resourceData}
          categoryData={categoryData}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicResource(
      limit: 100
      sort: { fields: data___post_date, order: DESC }
    ) {
      nodes {
        data {
          category {
            id
            uid
          }
          categories {
            item {
              id
            }
          }
          title
          resource_type
          resource_photo {
            gatsbyImageData
            url
          }
          post_date
          overlay_position
          group_of_subtags {
            subtag {
              id
              uid
            }
          }
        }
        uid
        prismicId
      }
    }
    allPrismicCategory(filter: { lang: { eq: "el-gr" } }) {
      nodes {
        prismicId
        uid
        data {
          title
          description
          color
          icon {
            url
          }
          overlay_img {
            gatsbyImageData
            url
          }
        }
      }
    }
    allPrismicSubtag {
      nodes {
        data {
          tag_name
          category {
            uid
            id
          }
        }
        prismicId
        uid
      }
    }
    allPrismicTrends(sort: { fields: data___order }) {
      nodes {
        data {
          destination {
            uid
            type
          }
          hashtag {
            text
          }
        }
        id
      }
    }
    allPrismicFeatured {
      nodes {
        data {
          is_popup
          announcement_url {
            url
            id
          }
          announcement_image {
            url
            gatsbyImageData
          }
          is_video
          hero {
            hero_elements {
              id
              document {
                ... on PrismicResource {
                  prismicId
                  uid
                  data {
                    category {
                      id
                      uid
                    }
                    categories {
                      item {
                        id
                      }
                    }
                    title
                    resource_type
                    resource_photo {
                      gatsbyImageData
                      url
                    }
                    post_date
                    overlay_position
                    group_of_subtags {
                      subtag {
                        id
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
