import Select from "react-select"
import React from "react"

export default function CategorySelect({ options, category, setCategory }) {
  const value = options.find(x => x.value === category)

  return (
    <div className="mb-3">
      <Select
        options={options}
        width="100%"
        value={value}
        onChange={option => setCategory(option.value)}
      />
    </div>
  )
}
